import React from 'react';
import Numeral from '../numeral';

import { StyledBubble } from './style';

const Bubble = ({ price }) => {
  let text;
  let className = 'bubble';

  if(price !== "") {
    if(price > 0){
      text = <Numeral value={price} format="$ 0,0.00" />;
    }
    else {
      text = 'Frete Grátis';
      className += ' free-shipping';
    }

    return (
      <StyledBubble className={className}>
        {text}
      </StyledBubble>
    )
  }
  else return null;
}

export default Bubble;
