import React from 'react';
import { useDispatch } from 'react-redux';
import { updateShipping } from '../../state/reducers/shipping';
import { Formik, Form, ErrorMessage } from 'formik';
import { Button } from '../button';
import NumberField from '../numberField';

import { StyledZipcodeForm } from './style';

export const ZipcodeForm = () => {
  const dispatch = useDispatch();

  const initialValues = { zipcode:  '' }

  const validate = (values) => {
    let errors = {};

    if(!values.zipcode){
      errors.zipcode = 'Digite o seu CEP';
    }

    return errors;
  }

  const handleSubmit = (values) => {
    dispatch(updateShipping({
      city: values.zipcode,
      zipcode: values.zipcode,
      currentPage: 'calculator',
    }));
  }

  return (
    <StyledZipcodeForm>
      <Formik
        initialValues={initialValues}
        onSubmit={handleSubmit}
        validate={validate}
      >
        <Form>
          <div class="form-title">
            Calcule o seu frete abaixo
          </div>

            <NumberField
              name="zipcode"
              format="#####-###"
              placeholder="Digite o seu CEP"
              className="input"
            />

            <Button type="submit" size="small" buttonStyle="inverse">
              Calcular frete
            </Button>

            <div className="error-message">
              <ErrorMessage name="zipcode" />
            </div>


        </Form>
      </Formik>
    </StyledZipcodeForm>
  );
}

export default ZipcodeForm;
