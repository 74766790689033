import React from 'react';
import { useField } from 'formik';
import NumberFormat from 'react-number-format';

export const NumberField = (props) => {
  const { name } = props
  const [field] = useField(name)

  return <NumberFormat {...field} {...props} />
}

export default NumberField
