import React from 'react';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import api from '../../services/api';

import Container from '../container';
import { Button } from '../button';

import { StyledNewsletter } from './style';

const Newsletter = () => {
  const initialValues = { email:  '' }

  const validate = (values) => {
    let errors = {};

    if(!values.email){
      errors.email = 'Digite o seu email';
    }
    else if(!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)){
      errors.email = 'Digite um email válido';
    }

    return errors;
  }

  const handleSubmit = (values) => {
    api.post('/newsletter_subscribers/subscribe', {
      email: values.email
    }).then((response) => {
      if(response.data.success){
        window.location.href = response.data.redirect_url;
      }
      else {
        alert(response.data.error);
      }
    }).catch(() => {});
  }

  return(
    <StyledNewsletter>
      <Container>
        <h3>
          Receba informações sobre o universo dos cafés especiais
        </h3>

        <p>
          Cadastre-se para dicas exclusivas, promoções, sugestões de formas de preparo e histórias sobre os melhores cafés.
        </p>

        <Formik
          initialValues={initialValues}
          onSubmit={handleSubmit}
          validate={validate}
        >
          <Form>
            <Field name="email" className="input" placeholder="Digite o seu email" />

            <Button type="submit">Cadastrar</Button>

            <div className="error-message">
              <ErrorMessage name="email" />
            </div>
          </Form>
        </Formik>
      </Container>
    </StyledNewsletter>
  )
}

export default Newsletter;
