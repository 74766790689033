import React from 'react';
import styled from 'styled-components';

export const StyledContainer = styled.div`
  max-width: ${({ theme }) => theme.containerWidth};
  margin: 0 auto;
`

function Container({ children }){
  return(
    <StyledContainer className="container">{children}</StyledContainer>
  )
}

export default Container;
