import React, { useState, useEffect } from 'react';
// import { withPrefix } from 'gatsby';
import { useSelector, useDispatch } from 'react-redux';
import { updateCurrentReview } from '../../state/reducers/currentReview';
import _ from 'lodash';

import { Row, Col } from '../grid';
import Title from '../title';
import Description from '../description';
import Container from '../container';
import SliderItem from './sliderItem';
import Approval from './approval';
import ReviewModal from './reviewModal';

import Slider from 'react-slick';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import fb1 from '../../images/reviews/fb1.jpg';
import fb2 from '../../images/reviews/fb2.jpg';
import fb3 from '../../images/reviews/fb3.jpg';
import fb4 from '../../images/reviews/fb4.jpg';
import fb5 from '../../images/reviews/fb5.jpg';
import fb6 from '../../images/reviews/fb6.jpg';
import fb7 from '../../images/reviews/fb7.jpg';

import { StyledReviews } from './style';

const Reviews = () => {
  const dispatch = useDispatch();

  const currentReview = useSelector((state) => state.currentReview);
  const [modalIsOpen, setModalIsOpen] = useState(false)

  const perPage = 1;
  const reviews = [
    {
      type: 'image',
      thumb: fb1,
      url: fb1
    },
    {
      type: 'image',
      thumb: fb2,
      url: fb2
    },
    {
      type: 'image',
      thumb: fb3,
      url: fb3
    },
    {
      type: 'image',
      thumb: fb4,
      url: fb4
    },
    {
      type: 'image',
      thumb: fb5,
      url: fb5
    },
    {
      type: 'image',
      thumb: fb6,
      url: fb6
    },
    {
      type: 'image',
      thumb: fb7,
      url: fb7
    }
  ]

  const groups = _.chunk(reviews, perPage);

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    autoplay: true,
    autoplaySpeed: 7000,
    slidesToShow: 1,
    slidesToScroll: 1
  }

  const handleModalCloseClick = () => {
    dispatch(updateCurrentReview(""));
  }

  useEffect(() => {
    setModalIsOpen(Boolean(currentReview));
  }, [currentReview]);

  return (
    <>
      <StyledReviews>
        <Container>
          <Row>
            <Col className="review-header">
              <Title>Satisfação garantida</Title>

              <Description>
                Na coffee&joy, não só buscamos proporcionar a melhor experiência em cafés especiais, mas também nos dedicamos a cuidar de cada membro de maneira única.
              </Description>
              <Description>
                <strong>
                  É fácil falar, mas difícil fazer - por isso, compartilhamos alguns relatos aqui.
                </strong>
              </Description>

              <Approval />
            </Col>
            <Col className="review-list">
              <div className="reviews-box">
                <div className="slider-wrapper">
                  <div className="slider">
                    <Slider {...settings}>
                      {groups.map((group, i) => (
                        <SliderItem reviews={group} key={i} />
                      ))}
                    </Slider>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </StyledReviews>

      <ReviewModal isOpen={modalIsOpen} onCloseClick={handleModalCloseClick} />
    </>
  )
}

export default Reviews;
