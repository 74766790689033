import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { updateShipping } from '../../state/reducers/shipping';
import Bubble from './bubble';
import { Slider, Thumb, Track } from './slider';
import { TextButton } from '../button';
import _ from 'lodash';

import { StyledCalculator } from './style';

import arrow from '../../images/arrow.svg';

export const Calculator = () => {
  const dispatch = useDispatch();

  const shipping = useSelector((state) => state.shipping);

  const defaultQuantity = 2;

  const handleEditClick = () => {
    dispatch(updateShipping({
      currentPage: 'form'
    }));
  }

  const handleSliderChange = (value) => {
    calculateShippingPrice(value);
  }

  const calculateShippingPrice = (quantity) => {
    dispatch(updateShipping({
      currentPrice: shipping.prices[quantity]
    }));
  }

  useEffect(() => {
    if(_.size(shipping.prices)) calculateShippingPrice(defaultQuantity);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shipping.prices]);

  return (
    <StyledCalculator>
      <div className="warning">
        <img src={arrow} alt="" className="arrow" />

        <span className="text">Arraste para calcular</span>
      </div>

      <h3>
        Frete para <strong>{shipping.city}</strong>

        <small>
          (<TextButton onClick={handleEditClick}>editar</TextButton>)
        </small>
      </h3>

      <div className="slider-wrapper">
        <Slider
          min={1}
          max={shipping.max}
          onChange={handleSliderChange}
          defaultValue={defaultQuantity}
          renderTrack={Track}
          renderThumb={Thumb}
        />

        <Bubble price={shipping.currentPrice} />
      </div>
    </StyledCalculator>
  )
}

export default Calculator;
