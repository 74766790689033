import React from 'react';
import Container from './container';
import styled from 'styled-components';
import Title from './title';
import Subtitle from './subtitle';
import Description from './description';
import { LinkButton } from './button';

import cafeinas from '../images/cafeinas.svg';

export const StyledPoints = styled.div`
  padding: 100px 0;
  text-align: center;

  .container {
    max-width: 960px;
  }

  img {
    margin: 50px 0 30px;
    max-width: 100%;
    height: 250px;
  }

  .subtitle {
    color: ${props => props.theme.colors.primary};
  }

  ul {
    /* list-style: disc; */
    /* padding: 0 0 0 30px; */
    font-size: ${({ theme }) => theme.fontSizes.normal};
    line-height: 1.8;
    /* opacity: 0.6; */
    margin: 15px 0 0;
  }

  .actions {
    margin: 50px 0 0;
  }

  @media(max-width: ${props => props.theme.breakpoints.mobile}) {
    padding: 30px 15px;

    img {
      height: auto;
      margin: 30px 0 15px;
    }

    .actions {
      margin: 30px 0 0;
    }
  }
`

const Points = () => {
  return(
    <StyledPoints>
      <Container>
        <Subtitle>Cafeínas</Subtitle>
        <Title>Ganhe pontos tomando café</Title>

        <img src={cafeinas} alt="" />

        <Description>
          Aqui na coffee&joy, cafeína não é só na xícara! Cada vez que você compra seus cafés, produtos na loja ou faz as tarefas no nosso app, você ganha cafeínas que são as nossas moedas virtuais! E você pode trocar elas por produtos em nossa loja que são enviados na sua caixinha!
        </Description>

        <div className="actions">
          <LinkButton
            href="/cafeinas?utm_source=site&utm_medium=header-cta"
            buttonStyle="inverse"
          >
            Saiba mais sobre as cafeínas da coffee&joy!
          </LinkButton>
        </div>
      </Container>
    </StyledPoints>
  )
}

export default Points;
