import React from 'react';

import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from 'react-accessible-accordion';

import Title from '../title';
import Subtitle from '../subtitle';
import Container from '../container';
import Icon from '../icon';

import { StyledFaq } from './style';

const Faq = () => {
  const Question = ({ question }) => {
    return (
      <AccordionItem>
        <div className="question">
          <AccordionItemHeading>
            <AccordionItemButton>
              <p className="text">
                <Icon icon="caret-right" />
                {question.text}
              </p>
            </AccordionItemButton>
          </AccordionItemHeading>

          <AccordionItemPanel>
            <p className="answer">{question.answer}</p>
          </AccordionItemPanel>
        </div>
      </AccordionItem>
    )
  }

  const questions = [
    {
      text: 'Quanto custa os cafés da Coffee & Joy?',
      answer: `No plano de assinatura, cada café especial custa apenas R$34,80 o pacote com 250g.
      Lembrando que são todos micro-lotes limitadíssimos e, mais do que isso, nossos cafés, além de
      terem sabores naturalmente diferenciados e serem exóticos, vão abarrotados de história e muito, muito trabalho
      por trás dos grãos.`
    },
    {
      text: 'Vou receber o café fresco? Quando o café foi torrado?',
      answer: `Sim! Torramos novos lotes de cafés toda semana, pelo menos duas vezes. Assim, os cafés que enviamos durante
      a semana, foram torrados há poucos dias e a informação com a data da torra vai estampada na embalagem,
      para não ter dúvidas.`
    },
    {
      text: 'O que ganho sendo assinante?',
      answer: `Oferecemos desconto progressivo no frete a medida em que você adiciona cafés ao seu pedido recorrente, podendo até chegar a zero.
      Essa funcionalidade está disponível apenas para nossos assinantes! Você também ganha cafeínas sendo assinante ativo. As cafeínas podem ser
      trocadas por produtos da nossa loja. Além disso, você também recebe surpresas exclusivas na sua caixinha.
      Por exemplo, no quarto envio do pedido recorrente, enviamos uma super caneca C&J. Para completar o pacote, quando você convidar amigos para ingressar no universo C&J
      utilizando seu link de assinante (disponível na sua área de usuário), lhe enviamos café de graça (cada 1 novo amigo assinante = 1 café!)`
    },
    {
      text: 'Posso escolher receber moído ou em grãos?',
      answer: `Claro! Aqui é você no controle. Se escolher moído, enviamos na granulometria ideal para o seu método de preparo e moemos
      na data da postagem, para garantir ainda mais frescor para o café que você vai fazer em casa ou no trabalho!`
    },
    {
      text: 'Posso trocar o café a cada novo envio?',
      answer: `Sim sim! Após cada envio, é possível alterar o pedido da forma que quiser. É só utilizar a
      sua aréa de assinante ou nosso aplicativo (disponível para IOS e Android). Se preferir você também
      pode deixar a sua assinatura no modo aleatório. Nesse modo, o sistema fica responsável por sortear cafés
      diferentes a cada envio feito!`
    },
    {
      text: 'Existe prazo de fidelização?',
      answer: `De jeito nenhum! Desenvolvemos um programa fácil e sem burocracias, para que você tenha total controle na sua conta e sua assinatura.
      Assim, além de poder alterar o pedido da forma que melhor te atender, também é possível pausar quando quiser, sem taxas adicionais, burocracias, nada!`
    },
    {
      text: 'Qual a pontuação dos cafés oferecidos pela Coffee & Joy?',
      answer: `São todos cafés acima de 84 pontos, chegando a até 89 pontos, avaliados por Q-Grader, profissional autorizado para realizar esse tipo de avaliação.
      Toda avaliação segue o Protocolo de Avaliação da Specialty Coffee Association of America - SCAA`
    },
    {
      text: 'Posso ter multiplos endereços de entrega?',
      answer: `Claro! Nosso sistema é flexível o bastante para permitir que você tenha diversos endereços cadastrados. Dessa maneira, fica fácil
      receber café em casa ou no trabalho!`
    }
  ]
  return(
    <StyledFaq>
      <Container>
        <h3>
          aproximamos você de quem faz seu café e garantimos a qualidade
          em todas as etapas
        </h3>
        <div className="flow"></div>

        <Subtitle>
          Ainda com dúvidas?
        </Subtitle>

        <Title>
          Perguntas frequentes
        </Title>

        <div className="faq-box">
          <Accordion allowZeroExpanded allowMultipleExpanded>
            {questions.map((question, i) => {
              return <Question
                question={question}
                key={i}
              />
            })}
          </Accordion>
        </div>
      </Container>
    </StyledFaq>
  )
}

export default Faq;
