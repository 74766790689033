import styled from 'styled-components';

export const StyledStore = styled.div`
  padding: 100px 0;

  .subtitle {
    background: ${({ theme }) => theme.colors.highlight};
    color: #fff;
    display: inline-block;
    padding: 8px 10px 7px;
    border-radius: ${({ theme }) => theme.borderRadius};
    text-transform: uppercase;
    font-size: ${({ theme }) => theme.fontSizes.small};
  }

  .box {
    display: flex;
    margin: 30px -15px 0;
  }

  .actions {
    margin: 30px 0 0;
  }

  @media(max-width: ${props => props.theme.breakpoints.mobile}) {
    padding: 30px 15px;

    .box {
      flex-wrap: wrap;
    }
  }
`

export const StyledProduct = styled.div`
  width: 25%;
  text-align: center;
  border-radius: ${({ theme }) => theme.borderRadius};
  padding: 15px 15px 30px;
  transition: all 0.5s ease;
  position: relative;

  a {
    text-decoration: none;
  }

  .product-image {
    display: block;
    position: relative;
    word-break: break-all;

    img {
      width: 100%;
    }
  }

  .product-name {
    display: block;
    font-size: ${({ theme }) => theme.fontSizes.small};
    line-height: 1.6;
    color: #333;
    margin: 10px 0 0;
  }

  .product-original-price {
    color: #999;
    text-transform: lowercase;
    display: block;
    margin: 15px 0 -3px;
    font-size: ${({ theme }) => theme.fontSizes.small};

    .original-price {
      text-decoration: line-through;
      text-transform: none;
    }
  }

  .product-price {
    font-size: ${({ theme }) => theme.fontSizes.normal};
    display: block;
    font-weight: bold;
    margin: 10px 0 0;

    &.produt-price-no-stock {
      color: #999;
    }
  }
  .no-stock {
    color: #999;
    display: block;
    font-size: ${({ theme }) => theme.fontSizes.small};
    text-transform: lowercase;
    margin-top: 10px;
  }

  .product-discount {
    position: absolute;
    top: 0;
    right: 0;
    background: #87c116;
    color: #FFF;
    padding: 7px 10px;
    font-size: ${({ theme }) => theme.fontSizes.small};
    font-weight: bold;
  }

  &:hover {
    background: #FFF;
    box-shadow: 0 0 15px rgba(0,0,0, 0.3);
  }

  @media(max-width: ${props => props.theme.breakpoints.mobile}) {
    width: 50%;
  }
`
