import styled from 'styled-components';

export const StyledCookieWarning = styled.div`
  position: fixed;
  background: #fff;
  width: 95%;
  padding: 15px 150px 15px 15px;
  border-radius: ${props => props.theme.borderRadius};
  box-shadow: 0 1px 3px rgba(0,0,0, 0.5);
  bottom: 10px;
  left: 50%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%%);
  z-index: 9999999999;

  .button {
    position: absolute;
    top: 50%;
    right: 15px;
    margin-top: -18px;
  }

  .description {
    margin: 0;
    font-size: ${props => props.theme.fontSizes.extraSmall};
    line-height: 1.4;
  }

  @media(max-width: ${props => props.theme.breakpoints.mobile}) {
    padding: 10px;

    .button {
      position: static;
      margin: 10px 0 0;
    }
  }
`
