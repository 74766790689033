import styled, { css } from 'styled-components';

let buttonStyle = css`
  background: ${props => props.theme.colors.primary};
  border: none;
  border-radius: ${props => props.theme.borderRadius};
  color: #fff;
  font-size: ${({ theme }) => theme.fontSizes.normal};
  font-weight: 600;
  height: 50px;
  padding: 0 25px;
  position: relative;
  cursor: pointer;
  text-align: center;
  text-decoration: none;
  display: inline-flex;
  align-items: center;

  &:hover {
    background-color: ${props => props.theme.colors.hover.primary};
    color: #fff;
  }

  &.button-inverse {
    background: transparent;
    border: 1px ${props => props.theme.colors.primary} solid;
    color: ${props => props.theme.colors.primary};
  }

  &.button-large {
    height: 60px;
    font-size: ${props => props.theme.fontSizes.large};
    padding: 0 30px;
  }

  &.button-small {
    height: 36px;
    font-size: ${props => props.theme.fontSizes.small};
    padding: 0 15px;
  }
`

export const IconWrapper = styled.span`
  font-size: 24px;
  margin: -13px 0 0;
  position: absolute;
  left: 15px;
  top: 50%;
  opacity: 0.6;
`;

export const IconWrapperRight = styled(IconWrapper)`
  left: auto;
  right: 15px;
`

export const StyledButton = styled.button`
  ${buttonStyle}
`

export const StyledLinkButton = styled.a`
  ${buttonStyle}
`

export const StyledTextButton = styled.button`
  background: none;
  border: none;
  padding: 0;
  line-height: 1;
  margin: 0;
  cursor: pointer;
  color: ${props => props.theme.colors.primary};
  text-decoration: underline;

  &:hover {
    color: ${props => props.theme.colors.darkenPrimary};
  }
`
