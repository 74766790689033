import React from 'react';
import styled from 'styled-components';

export const StyledSubtitle = styled.h2`
  font-size: ${({ theme }) => theme.fontSizes.normal};
  color: ${({ theme }) => theme.colors.highlight};
  font-weight: 600;

  & + .title {
    margin-top: 15px;
  }
`

function Subtitle({ children }){
  return(
    <StyledSubtitle className="subtitle">{children}</StyledSubtitle>
  )
}

export default Subtitle;
