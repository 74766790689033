import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { updateShipping } from '../../state/reducers/shipping';
import api from '../../services/api';
import _ from 'lodash';

import { StyledShipping } from './style';

import Title from '../title';
import Subtitle from '../subtitle';
import { LinkButton } from '../button';
import Description from '../description';
import { Row, Col } from '../grid';
import ZipcodeForm from './zipcodeForm';
import Calculator from './calculator';

import '@fontsource/caveat';

function Shipping(){
  const dispatch = useDispatch();

  const max = 10;
  const shipping = useSelector((state) => state.shipping);

  const findZipcode = () => {
    api.get('/cities/find-by-ip').then((response) => {
      const city = response.data.city;

      if(city && city.zipcode){
        dispatch(updateShipping({
          city: city.name,
          zipcode: city.zipcode,
        }));
      }
      else {
        setDefaultCity();
      }
    }).catch(() => {
      setDefaultCity();
    });
  }

  const setDefaultCity = () => {
    dispatch(updateShipping({
      city: 'São Paulo',
      zipcode: '4811210',
    }));
  }

  const requestShippings = () => {
    api.get('/shippings', {
      params: {
        zipcode: shipping.zipcode,
        max: max
      }
    }).then((response) => {
      const shippings = response.data.shippings;
      let shippingData = {};

      if(shippings){
        _.forOwn(shippings, (value, key) => {
          shippingData[key] = value.discounted_price;
        })

        dispatch(updateShipping({
          prices: shippingData
        }));

        dispatch(updateShipping({
          currentPage: 'calculator'
        }));
      }
    }).catch(() => {});
  }

  useEffect(() => {
    if(!shipping.zipcode) return;

    requestShippings();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shipping.zipcode]);

  useEffect(() => {
    findZipcode();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return(
    <StyledShipping>
      <div className="box">
        <Row>
          <Col>
            <Subtitle>Envio rápido e econômico</Subtitle>
            <Title>Desconto progressivo</Title>

            <Description>
              Quanto mais cafés você adicionar na sua assinatura, mais barato fica seu frete! Simule você mesmo e veja o resultado!
            </Description>
          </Col>

          <Col className="shipping-col">
            {shipping.currentPage === 'form' ? <ZipcodeForm /> : ''}
            {shipping.currentPage === 'calculator' ? <Calculator /> : ''}
          </Col>
        </Row>

        <div className="actions">
          <LinkButton
            href="/assinar?utm_source=site&utm_medium=shipping-cta"
          >
            Criar assinatura
          </LinkButton>
        </div>
      </div>
    </StyledShipping>
  )
}

export default Shipping;
