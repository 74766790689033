import React from 'react';
import styled from 'styled-components';

export const StyledDescription = styled.p`
  font-size: ${({ theme }) => theme.fontSizes.normal};
  line-height: 1.6;
  margin: 10px 0 0;

  @media(max-width: ${props => props.theme.breakpoints.mobile}) {
    font-size: ${({ theme }) => theme.fontSizes.small};
  }
`

function Description({ children }){
  return(
    <StyledDescription className="description">{children}</StyledDescription>
  )
}

export default Description;
