import React, { useState, useEffect } from 'react';

import { StaticImage } from 'gatsby-plugin-image';
import mug1 from '../../images/mug-1.png';
import mug2 from '../../images/mug-2.png';
import mug3 from '../../images/mug-3.png';
import mug4 from '../../images/mug-4.png';
import chevronLeft from '../../images/chevron-left.svg';
import chevronRight from '../../images/chevron-right.svg';

import { Row, Col } from '../grid';
import Title from '../title';
import Subtitle from '../subtitle';
import Description from '../description';
import { LinkButton } from '../button';
import Container from '../container';
import ReactInterval from 'react-interval';

import { StyledTeodoro } from './style';

const Teodoro = () => {
  const [currentMug, setCurrentMug] = useState(null);
  const [destroyInterval, setDestroyInterval] = useState(false);

  const mugs = [mug1, mug2, mug3, mug4];

  const handleInterval = () => {
    nextMug();
  }

  const prevMug = () => {
    let index = currentMug - 1;
    if(index === -1) index = mugs.length - 1;

    setCurrentMug(index);
    setDestroyInterval(true);
  }

  const nextMug = () => {
    let index = currentMug + 1;
    if(!mugs[index]) index = 0;

    setCurrentMug(index);
    setDestroyInterval(true);
  }

  useEffect(() => {
    if(destroyInterval === true) setDestroyInterval(false);
  }, [destroyInterval]);

  useEffect(() => {
    setCurrentMug(0);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return(
    <StyledTeodoro>
      {destroyInterval ? null : <ReactInterval timeout={3000} enabled={true} callback={handleInterval} />}

      <div className="box">
        <Container>
          <Row>
            <Col>
              <StaticImage
                src="../../images/teodoro.png"
                width={130}
                alt=""
                className="teodoro"
              />
              <Subtitle>Você sabe qual é o seu café favorito?</Subtitle>
              <Title>O Teodoro te ajuda</Title>
              <Description>
                Como cada café proporciona uma experiência sensorial diferente, o Teodoro pode te ajudar a escolher o café que mais pode te agradar. É só conversar com ele para descobrir!
              </Description>

              <div className="actions">
                <LinkButton
                  href="/quiz?utm_source=site&utm_medium=header-cta"
                  buttonStyle="inverse"
                >
                  Conversar com o Teodoro
                </LinkButton>
              </div>
            </Col>
            <Col>
              <div className="mug">
                <img src={mugs[currentMug]} alt="" className="mug-image" />

                <div className="control-button control-left" onClick={prevMug}>
                  <img src={chevronLeft} alt=">" />
                </div>
                <div className="control-button control-right" onClick={nextMug}>
                  <img src={chevronRight} alt=">" />
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </StyledTeodoro>
  )
}

export default Teodoro;
