import axios from 'axios';

axios.defaults.withCredentials = true;

const api = axios.create({
  baseURL: `${process.env.GATSBY_API_BASE_URL}/api`,
});

export const storeApi = axios.create({
  baseURL: `${process.env.GATSBY_STORE_API_BASE_URL}/api`,
});

export default api;
