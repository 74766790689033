import React from 'react';
import Review from './review';

const SliderItem = ({ reviews }) => {
  return (
    <div className="slider-item">
      {reviews.map((review, i) => (
        <Review review={review} key={i} />
      ))}
    </div>
  )
}

export default SliderItem;
