import styled from 'styled-components';

export const StyledNewsletter = styled.div`
  padding: 100px 0;
  background: #f8f8f8;
  text-align: center;

  h3 {
    font-size: ${({ theme }) => theme.fontSizes.large};
    font-weight: 600;
  }

  p {
    font-size: ${({ theme }) => theme.fontSizes.small};
    line-height: 1.6;
    margin: 2px 0 30px;
  }

  .input {
    border: 1px ${({ theme }) => theme.colors.border} solid;
    border-radius: ${props => props.theme.borderRadius};
    font-size: ${({ theme }) => theme.fontSizes.normal};
    line-height: 50px;
    padding: 0 20px;
    width: 350px;

    & + .button {
      margin-left: 10px;
    }
  }

  .error-message {
    color: ${({ theme }) => theme.colors.primary};
    font-size: ${({ theme }) => theme.fontSizes.small};
    margin: 15px 0 0;
  }

  @media(max-width: ${props => props.theme.breakpoints.mobile}) {
    padding: 30px 15px;

    p {
      margin: 10px 0 30px;
    }

    .input {
      width: 100%;

      & + .button {
        margin: 10px 0 0;
      }
    }
  }
`
