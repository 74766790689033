import React from 'react';
import styled from 'styled-components';

import { StaticImage } from 'gatsby-plugin-image';
import downloadAppStore from '../images/download-app-store.svg';
import downloadGooglePlay from '../images/download-google-play.svg';

import { Row, Col } from './grid';
import Title from './title';
import Subtitle from './subtitle';
import Description from './description';
import Container from './container';

export const StyledMobileApp = styled.div`
  background: #d05858;
  background: linear-gradient(35deg,#d05858 0%,#fff189 65%);
  padding: 100px 0;

  .subtitle {
    color: #d05858;
  }

  .icons {
    margin: 30px 0 0;

    a {
      & + a {
        margin-left: 10px;
      }
    }

    img {
      height: 50px;
    }
  }

  @media(max-width: ${props => props.theme.breakpoints.mobile}) {
    padding: 30px 15px;
    text-align: center;

    .subtitle {
      margin-top: 15px;
    }

    .description {
      opacity: 1;
    }

    .icons {
      img {
        height: 40px;
      }
    }
  }
`

const MobileApp = () => {
  return(
    <StyledMobileApp>
      <Container>
        <Row>
          <Col>
            <StaticImage
              src="../images/mobile-app.png"
              quality={100}
              width={680}
              alt=""
            />
          </Col>
          <Col>
            <Subtitle>Baixe nosso app</Subtitle>
            <Title>Seus cafés na palma da sua mão</Title>
            <Description>
              Controle sua assinatura em qualquer lugar de um jeito fácil e rápido!
            </Description>

            <div className="icons">
              <a href="https://play.google.com/store/apps/details?id=com.candjapp" target="_blank" rel="noreferrer">
                <img src={downloadGooglePlay} alt="Baixar na Google Play" />
              </a>

              <a href="https://apps.apple.com/ca/app/coffee-joy/id1486471617" target="_blank" rel="noreferrer">
                <img src={downloadAppStore} alt="Baixar na App Store" />
              </a>
            </div>
          </Col>
        </Row>
      </Container>
    </StyledMobileApp>
  )
}

export default MobileApp;
