import styled from 'styled-components';
import flow from '../../images/flow.svg';
import flowMobile from '../../images/flow-mobile.svg';

export const StyledFaq = styled.div`
  padding: 100px 0;

  h3 {
    text-align: center;
    margin: 0 0 50px;
    font-size: ${({ theme }) => theme.fontSizes.large};
    font-weight: 600;
    color: ${({ theme }) => theme.colors.primary};
  }

  .flow {
    text-align: center;
    margin: 0 0 50px;
    background: url(${flow});
    background-repeat: no-repeat;
    background-position: center;
    height: 350px;
  }

  h4 {
    text-align: center;
    font-size: ${({ theme }) => theme.fontSizes.extraLarge};
  }

  .description {
    text-align: center;
  }

  .faq-box {
    background: rgb(200,200,200);
    background: linear-gradient(35deg, #eee 0%, #fff 50%);
    border: 1px ${({ theme }) => theme.colors.border} solid;
    border-radius: ${props => props.theme.borderRadius};
    margin: 50px 0 0;
  }

  .question {
    .text {
      font-weight: 600;
      padding: 30px;
      cursor: pointer;

      svg {
        margin-right: 15px;
      }
    }
    .answer {
      background: #fff;
      padding: 30px;
      line-height: 1.8;
    }
  }

  .accordion__item {
    & + .accordion__item {
      border-top: 1px ${({ theme }) => theme.colors.border} solid;
    }
  }

  @media(max-width: ${props => props.theme.breakpoints.mobile}) {
    padding: 30px 15px;

    h3 {
      margin-bottom: 30px;
      font-size: ${({ theme }) => theme.fontSizes.normal};
    }

    .flow {
      margin-bottom: 30px;
      background-image: url(${flowMobile});
      height: 600px;
    }

    .faq-box {
      margin-top: 30px;
    }

    .question {
      .text {
        line-height: 1.6;
      }
    }
  }
`
