import React, { useState, useEffect } from 'react';

import { StyledCookieWarning } from './style';
import { Button } from '../button';
import Description from '../description';

const CookiesWarning = () => {
  const key = 'cookieWarningViewed';
  const [value, setValue] = useState(undefined);

  const handleClick = () => {
    localStorage.setItem(key, true);
    setValue(true);
  }

  useEffect(() => {
    setValue(localStorage.getItem(key));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if(value !== undefined && !value) {
    return (
      <StyledCookieWarning>
        <Description>
          Nós usamos cookies e outras tecnologias semelhantes para melhorar a sua experiência em nossos serviços, personalizar publicidade e recomendar conteúdo de seu interesse. Ao utilizar nossos serviços, você concorda com tal monitoramento. Veja nossa <a href="/return-policy" target="_blank" rel="noreferrer noopener">Política de Privacidade</a>.
        </Description>

        <Button size="small" onClick={handleClick}>Prosseguir</Button>
      </StyledCookieWarning>
    )
  }
  else return null;
}

export default CookiesWarning;
