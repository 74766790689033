import React, { useEffect, useState } from 'react';
import { LinkButton } from '../button';
import api from '../../services/api';
import Slider from 'react-slick';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import { StyledBanners } from './style';

const Banners = () => {
  const [banners, setBanners] = useState([]);
  const [currentBanner, setCurrentBanner] = useState('');

  const settings = {
    dots: true,
    infinite: true,
    autoplay: true,
    autoplaySpeed: 5000,
    slidesToShow: 1,
    slidesToScroll: 1,
    afterChange: (i) => {
      setCurrentBanner(banners[i])
    }
  }

  const requestBanners = () => {
    api.get('/banners').then((response) => {
      if(response.data.banners){
        setBanners(response.data.banners);

        setCurrentBanner(response.data.banners[0])
      }
    }).catch(() => {});
  }

  useEffect(() => {
    requestBanners();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if(banners.length){
    return(
      <StyledBanners id="banners">
        <style>{`
          #header.floating {
            background: ${currentBanner.header_bg_color} !important;
          }

          #header .menu-icon {
            color: ${currentBanner.header_link_color};
          }

          #header .parent-menu li .menu-link {
            color: ${currentBanner.header_link_color};
          }

          #header .menu-cta {
            background: ${currentBanner.header_button_bg_color};
            color: ${currentBanner.header_button_text_color};
          }

          @media(max-width: 568px) {
            #header .parent-menu {
              background: ${currentBanner.header_bg_color} !important;
            }

            #banners .banner .banner-actions {
              background: ${currentBanner.actions_bg_color} !important;
            }

            #banners .banner .banner-actions .subscription-cta {
              background: ${currentBanner.mobile_subscription_button_bg_color} !important;
              color: ${currentBanner.mobile_subscription_button_text_color} !important;
            }

            #banners .banner .banner-actions .store-cta {
              border-color: ${currentBanner.mobile_store_button_bg_color} !important;
              color: ${currentBanner.mobile_store_button_text_color} !important;
            }
          }
        `}</style>

        {currentBanner.logo_style === 'white' && <style>
          {`
            #header .logo img {
              filter: brightness(0) invert(1);
            }
          `}
        </style>}

        <div className="banners-slide">
          <Slider {...settings}>
            {banners.map((banner, i) => (
              <div class="banner-slide" data-time={(banner.time + 1) * 1000} key={i}>
                <div className="banner" style={{ backgroundImage: `url("${banner.desktop_image_url}")`, backgroundColor: banner.header_bg_color }}>
                  <div className="banner-box">
                    <div className="headline">
                      <div className="headline-box">
                        <h1 style={{ color: banner.headline_text_color}}>
                          {banner.headline}
                        </h1>
        
                        <p style={{ color: banner.description_text_color}}>
                          {banner.description}
                        </p>
                      </div>
        
                      <img src={banner.mobile_image_url} className="banner-mobile" alt="" />
        
                      <div className="banner-actions">
                        <LinkButton
                          className="subscription-cta"
                          href="/assinar?utm_source=site&utm_medium=hero-cta"
                          size="large"
                          style={{ backgroundColor: banner.desktop_subscription_button_bg_color, color: banner.desktop_subscription_button_text_color }}
                        >
                          {banner.subscription_cta}
                        </LinkButton>
        
                        <LinkButton
                          className="store-cta button button-inverse"
                          href="/store?utm_source=site&utm_medium=hero-cta"
                          size="large"
                          buttonStyle="inverse"
                          style={{ borderColor: banner.desktop_store_button_bg_color, color: banner.desktop_store_button_text_color }}
                        >
                          {banner.store_cta}
                        </LinkButton>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </Slider>
        </div>
      </StyledBanners>
    )
  }
  else return null;
}

export default Banners;
