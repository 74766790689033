import styled from 'styled-components';

export const StyledTeodoro = styled.div`
  .box {
    background: rgb(200,200,200);
    background: linear-gradient(135deg, rgba(255,255,255,1) 40%, rgb(215, 215, 215, 0.9) 90%);
    padding: 100px 0;
    position: relative;

    &:before, &:after {
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      height: 45%;
      width: 100%;
      background: linear-gradient(135deg,rgba(255,255,255,1) 0%, rgba(0,0,0, 0.1) 90%);
      z-index: 9;
    }
  }

  .mug {
    text-align: right;
    position: relative;

    .control-button {
      position: absolute;
      top: 55%;
      cursor: pointer;
      opacity: 0.3;
      z-index: 3;

      img {
        height: 20px;
      }

      &:hover {
        opacity: 1;
      }

      &.control-left {
        left: 125px;
      }

      &.control-right {
        right: -30px;
      }
    }
  }

  .teodoro {
    margin: 0 0 30px;
  }

  .actions {
    margin: 50px 0 0;

    button {
      border-color: ${({ theme }) => theme.colors.highlight};
      color: ${({ theme }) => theme.colors.highlight};
    }
  }

  .row {
    position: relative;
    z-index: 99;
  }

  @media(max-width: ${props => props.theme.breakpoints.mobile}) {
    .box {
      padding: 30px 15px;
      text-align: center;

      &:before, &:after {
        display: none;
      }
    }

    .actions {
      margin-top: 30px;
    }

    .mug {
      text-align: center;
      margin: 30px 0 0;

      img {
        max-width: 80%;
      }

      .control-button {
        &.control-left {
          left: 0;
        }

        &.control-right {
          right: 0;
        }
      }
    }
  }
`
