import React from 'react';
import { useDispatch } from 'react-redux';
import { updateCurrentReview } from '../../state/reducers/currentReview';

const Review = ({ review }) => {
  const dispatch = useDispatch();

  const handleClick = (review) => {
    dispatch(updateCurrentReview(review));
  }

  return (
    <div className="review" data-type={review.type} onClick={() => { handleClick(review) }}>
      <img src={review.thumb} alt="" />
    </div>
  )
}

export default Review;
