import styled from 'styled-components';

import play from '../../images/play.svg';
import search from '../../images/search-plus.svg';

export const StyledReviews = styled.div`
  padding: 100px 0;
  background: rgb(200,200,200);
  background: linear-gradient(35deg, #fff 0%, #eee 100%);

  .review-header {
    width: 40%;
    padding-right: 70px;
  }

  .review-list {
    width: 60%;
  }

  .reviews-box {
  }

  .title {
    font-size: ${({ theme }) => theme.fontSizes.big};
    background: linear-gradient(135deg, ${props => props.theme.colors.primary} 0%, ${props => props.theme.colors.highlight} 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    line-height: 1.2;
  }

  .slider-wrapper {

  }

  .slider {
    position: relative;
    z-index: 9;
    padding: 0;
  }

  .slider-item {
    display: flex;

    .review {
      width: 85%;
      position: relative;
      margin: 0 auto;
      border: 1px #ddd solid;

      img {
        width: 100%;
        border-radius: ${props => props.theme.borderRadius};
      }

      /* &:before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: linear-gradient(0, rgba(0,0,0,0.7) 0%, rgba(0,0,0,0.1) 40%);
      }

      &:after {
        content: '';
        position: absolute;
        top: 50%;
        left: 50%;
        height: 30px;
        width: 30px;
        background: url(${search}) no-repeat center 100%;
        -webkit-transform: translate(-50%, -50%);
                transform: translate(-50%, -50%);
      } */

      &:hover {
        cursor: pointer;

        &:before {
          background: linear-gradient(0, rgba(0,0,0,0.9) 0%, rgba(0,0,0,0.3) 40%);
        }
      }

      &[data-type="video"] {
        &:after {
          background-image: url(${play});
        }
      }

      & + .review {
        margin-left: 1.3%
      }
    }
  }

  .slick-dots {
    position: static;
    margin: 30px 0 0;

    li button:before {
      font-size: 10px;
    }
  }

  .slick-prev, .slick-next {
    height: 30px;
    width: 30px;
    cursor: pointer;
  }

  .slick-prev {
    left: -40px;
  }

  .slick-next {
    right: -40px;
  }

  .slick-prev:before, .slick-next:before {
    color: #000;
    opacity: 0.3;
    z-index: 9;
    font-size: 30px;
  }

  .seal {
    margin: 0 0 30px;

    img {
      height: 100px;
    }
  }

  @media(max-width: ${props => props.theme.breakpoints.mobile}) {
    padding: 30px 15px;

    .review-header {
      width: auto;
      padding-right: 0;
    }

    .review-list {
      width: auto;
    }

    .title {
      font-size: 26px;
    }

    .slick-prev, .slick-next {
      display: none !important;
    }
  }
`

export const StyledApproval = styled.div`
  display: inline-flex;
  align-items: baseline;
  margin: 30px 0 0;
  padding: 15px 20px 15px 20px;
  border-radius: ${props => props.theme.borderRadius};
  background: #f6f6f6;

  .thumbs-up {
    height: 31px;
    position: relative;
    top: 2px;
  }

  big {
    font-size: ${({ theme }) => theme.fontSizes.big};
    font-weight: bold;
    margin: 0 10px 0 15px;
  }

  @media(max-width: ${props => props.theme.breakpoints.mobile}) {
    display: flex;
    background: #f1f1f1;
    margin: 15px 0;
  }
`

export const StyledReviewModal = styled.div`
  position: relative;
  height: 100%;

  .review-image {
    max-width: 550px;
    min-width: 600px;
    position: relative;
    padding-top: 62.5%;
    overflow: hidden;

    img {
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
    }
  }

  .review-video {
    height: 480px;
    position: relative;
    width: 270px;

    video {
      height: 100%;
      width: 100%;
      object-fit: cover;
      position: absolute;
      top: 0;
      left: 0;
    }
  }
`
