import React from 'react';
import Container from './container';
import Description from './description';

import styled from 'styled-components';

import box from '../images/box.svg';
import calendar from '../images/calendar.svg';
import truck from '../images/truck.svg';
import Subtitle from './subtitle';

export const StyledHowItWorks = styled.div`
  padding: 50px 0;

  .container {
    max-width: 760px;
  }

  .subtitle {
    text-align: center;
    margin: 0 0 30px;
  }

  .item {
    display: flex;

    .item-box {
      width: 50%;
      display: flex;
      align-content: center;
      justify-content: center;
      flex-wrap: wrap;
    }

    h4 {
      font-weight: 600;
      font-size: ${({ theme }) => theme.fontSizes.extraLarge};
      width: 100%;
    }

    .item-image {
      width: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 0 50px;

      img {
        height: 180px;

        &.image-box {
          height: 250px;
        }

        &.image-truck {
          height: 150px;
        }
      }
    }

    &:nth-child(2n) {
      .item-box {
        order: 1;
      }

      .item-image {
        order: 2;
      }
    }

    & + .item {
      margin: 70px 0 0;
    }
  }

  @media(max-width: ${props => props.theme.breakpoints.mobile}) {
    padding: 50px 15px;

    .item {
      padding: 0;
      display: block;
      text-align: center;

      .item-box {
        width: auto;
      }

      h4 {
        font-weight: 600;
        font-size: ${({ theme }) => theme.fontSizes.large};
      }

      .item-image {
        width: auto;
        margin: 0 0 15px;

        img {
          height: 80px;

          &.image-box {
            height: 100px;
          }

          &.image-truck {
            height: 70px;
          }
        }
      }

      & + .item {
        margin: 50px 0 0;
      }
    }
  }
`

const HowItWorks = () => {
  return(
    <StyledHowItWorks>
      <Container>
        <Subtitle>Como funciona?</Subtitle>

        <div className="item" data-number="1">
          <div className="item-image">
            <img src={box} alt="" className="image-box" />
          </div>

          <div className="item-box">
            <h4>Escolha seus cafés</h4>
            <Description>
              Você pode escolher seus cafés favoritos ou deixar que o nosso sistema escolha por você.
            </Description>
            
            <Description>
              Além disso, você ganha, todo mês, um mimo exclusivo de assinante! <a href="/mimos">Veja o que já enviamos!</a>
            </Description>
          </div>
        </div>

        <div className="item" data-number="2">
          <div className="item-image">
            <img src={calendar} alt="" className="image-calendar" />
          </div>

          <div className="item-box">
            <h4>Programe sua entrega</h4>
            <Description>
              Você define a frequência que deseja receber seus cafés e ainda pode alterar tudo sempre que desejar.
            </Description>
          </div>
        </div>

        <div className="item" data-number="3">
          <div className="item-image">
            <img src={truck} alt="" className="image-truck" />
          </div>

          <div className="item-box">
            <h4>Entregamos na sua casa</h4>
            <Description>
              Prontinho! Seu café fresquinho e recém torrado será enviado para a sua casa no próximo dia útil. Rápido, fácil e sem complicação!
            </Description>
          </div>
        </div>
      </Container>
    </StyledHowItWorks>
  )
}

export default HowItWorks;
