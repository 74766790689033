import React, { useEffect } from 'react';
import { storeApi } from '../../services/api';
import { useSelector, useDispatch } from 'react-redux';
import { updateProducts } from '../../state/reducers/products';
import _ from 'lodash';

import Title from '../title';
import Subtitle from '../subtitle';
import Description from '../description';
import Container from '../container';
import Product from './product';
import { LinkButton } from '../button'

import { StyledStore } from './style';

const Store = () => {
  const dispatch = useDispatch();

  const products = useSelector((state) => state.products);

  const requestProducts = () => {
    storeApi.get('/products?on_sale=true').then((response) => {
      if(response.data.products){
        let products = _.slice(response.data.products, 0, 4);

        dispatch(updateProducts(products));
      }
    }).catch(() => {});
  }

  useEffect(() => {
    requestProducts();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return(
    <StyledStore>
      <Container>
        <Subtitle>Frete grátis na loja para assinantes</Subtitle>
        <Title>Promoções imperdíveis</Title>
        <Description>
          Já é assinante coffee&joy? Seu frete é grátis! Receba sua compra junto com a sua próxima caixinha. <a href="/store/receber-junto-com-assinatura" target="_blank">Saiba mais</a>. <br />
          Não é assinante e quer comprar na loja? Fique a vontade e aproveite!
        </Description>

        <div className="box">
          {products.map((product, i) => {
            return <Product
              product={product}
              key={i}
            />
          })}
        </div>

        <div className="actions">
          <LinkButton
            href="/store?utm_source=site&utm_medium=store-cta"
          >
            Acesse nossa loja
          </LinkButton>
        </div>
      </Container>
    </StyledStore>
  )
}

export default Store;
