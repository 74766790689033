import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { Helmet } from 'react-helmet';
import Header from './header';
import Footer from './footer';

const Layout = ({ children }) => {
  const data = useStaticQuery(graphql`
    query SiteQuery {
      site {
        siteMetadata {
          title
          menuLinks {
            name
            link
            subMenu {
              name
              link
              target
            }
          }
        }
      }
    }
  `)

  const siteMetadata = data.site.siteMetadata;

  return (
    <React.Fragment>
      <Helmet>
        <title>Comprar cafés especiais online - coffee&joy</title>

        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <meta charset="utf-8" />
        <meta property="og:type" content="website" />
        <meta property="og:site_name" content="Coffee &amp; Joy" />
        <meta property="fb:app_id" content="949678671826756" />
        <meta name="keywords" content="café, café especial, coffee, cafés, cafés especiais, comprar cafés online, torra de café" />
        <meta property="og:title" content="Comprar cafés especiais online - coffee&joy" />
        <meta property="og:description" content="Selecionamos cafés especiais incríveis, direto do produtor e cheios de histórias. Enviamos recém torrado para a sua casa na frequência que você escolher. Aproveite nosso frete reduzido!" />
        <meta name="description" content="Selecionamos cafés especiais incríveis, direto do produtor e cheios de histórias. Enviamos recém torrado para a sua casa na frequência que você escolher. Aproveite nosso frete reduzido!" />
        <meta property="og:url" content="https://coffeeandjoy.com.br/" />
        <link rel="canonical" href="https://coffeeandjoy.com.br/" />
        <meta property="og:image" content="https://coffeeandjoy.com.br/sharing/default.png" />
      </Helmet>

      <Header menuLinks={siteMetadata.menuLinks} title={siteMetadata.title} />

      <div>
        {children}
      </div>

      <Footer menuLinks={siteMetadata.menuLinks} />
    </React.Fragment>
  )
}

export default Layout;
