import styled from 'styled-components';

export const StyledMenu = styled.nav`
  display: flex;
  height: 100%;

  .menu-icon, .close {
    display: none;
  }

  .menu-box {
    display: flex;
  }

  .parent-menu {
    display: flex;
    align-items: center;

    li {
      height: 100%;
      display: flex;
      align-items: center;
      position: relative;

      .menu-link {
        color: ${props => props.theme.colors.text};
        display: flex;
        align-items: center;
        height: 100%;
        padding: 0 15px;
        font-size: ${({ theme }) => theme.fontSizes.small};
        text-decoration: none;

        .caret {
          margin-left: 10px;
        }

        &:hover {
          color: ${props => props.theme.colors.primary};
        }
      }

      ul {
        display: none;
      }

      &.sep {
        margin-left: 50px;
        height: 50%;
      }

      &:hover > ul, &:focus-within > ul {
        display: block;
        position: absolute;
        top: 100%;
        left: 0;
        width: 200px;
        background: #fff;
        border: 1px ${props => props.theme.colors.border} solid;
        border-top: none;

        li {
          width: 100%;
          display: block;

          a {
            padding: 15px;
            display: block;
            line-height: 1.4;
            color: ${props => props.theme.colors.text} !important;
          }

          & + li {
            border-top: 1px ${props => props.theme.colors.border} dotted;
          }
        }
      }
    }
  }

  @media(max-width: 960px) {
    .menu-box {
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: rgba(0,0,0, 0.7);
      display: block;
      z-index: 9;
    }

    .menu-icon {
      background: none;
      border: none;
      display: block;
      width: 60px;
      font-size: 24px;
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
    }

    .close {
      position: absolute;
      top: 15px;
      display: block;
      right: 15px;
      background: none;
      color: #fff;
      font-weight: bold;
      border: none;
      font-size: ${({ theme }) => theme.fontSizes.big};
    }

    .parent-menu {
      position: fixed;
      top: 0;
      left: 0;
      width: 80%;
      height: 100%;
      background: #fff;
      display: block;
      padding: 20px 0 0;

      li {
        height: auto;
        display: block;

        .menu-link {
          padding: 15px 30px;
        }

        .button {
          width: auto;
          display: flex;
          margin: 15px 15px 0;
          justify-content: center;
        }

        &.sep {
          border-left: none;
          border-top: 1px ${props => props.theme.colors.border} solid;
          height: auto;
          margin: 10px 0;
        }

        &[data-has-submenu="true"] {
          > .menu-link {
            display: none;
          }
          
          ul {
            display: block;
            position: static;
            width: auto;
          }
        }
      }
    }

    &.hide-menu {
      .menu-box {
        display: none;
      }
    }
  }
`
