import React from 'react';
import styled from 'styled-components';

export const StyledTitle = styled.h1`
  font-size: ${({ theme }) => theme.fontSizes.bigger};
  color: ${({ theme }) => theme.colors.text};
  font-weight: bold;

  @media(max-width: ${props => props.theme.breakpoints.mobile}) {
    font-size: 26px;
  }
`

function Title({ children }){
  return(
    <StyledTitle className="title">{children}</StyledTitle>
  )
}

export default Title;
