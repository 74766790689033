import React from 'react';

import { library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faAward, faMagic, faCaretRight, faCaretDown, faBars
} from '@fortawesome/free-solid-svg-icons';


library.add(
  faAward,
  faMagic,
  faCaretRight,
  faCaretDown,
  faBars
)

export const Icon = (props) => {
  return(
    <FontAwesomeIcon {...props} />
  );
}

export default Icon;
