import React from 'react';
import { useSelector } from 'react-redux';
import { StyledCoffeePackage } from './style';

import shadow from '../../images/coffee-shadow.png';
import chevronLeft from '../../images/chevron-left.svg';
import chevronRight from '../../images/chevron-right.svg';

const CoffeePackage = ({ currentCoffee, prevCoffee, nextCoffee }) => {
  const coffees = useSelector((state) => state.coffees);

  if(currentCoffee !== null){
    const coffee = coffees[currentCoffee];

    if(coffee){
      return (
        <StyledCoffeePackage>
          <div className="package-box">
            <img src={coffee.package_image_url} alt={coffee.name} className="package" />
            <img src={shadow} alt="" className="shadow" />

            <div className="control-button control-left" onClick={prevCoffee}>
              <img src={chevronLeft} alt=">" />
            </div>
            <div className="control-button control-right" onClick={nextCoffee}>
              <img src={chevronRight} alt=">" />
            </div>
          </div>

          <div className="coffee-name">
            {coffee.name}
          </div>
        </StyledCoffeePackage>
      )
    }
    else return null;
  }
  else return null;
}

export default CoffeePackage;
