import styled from 'styled-components';

export const StyledModal = styled.div`
  @-webkit-keyframes slide-bottom {
    0% {
      -webkit-transform: translate(-50%, -100%);
              transform: translate(-50%, -100%);
    }
    100% {
      -webkit-transform: translate(-50%, -50%);
              transform: translate(-50%, -50%);
    }
  }
  @keyframes slide-bottom {
    0% {
      -webkit-transform: translate(-50%, -100%);
              transform: translate(-50%, -100%);
    }
    100% {
      -webkit-transform: translate(-50%, -50%);
              transform: translate(-50%, -50%);
    }
  }

  .overlay {
    background: rgba(0,0,0, 0.8);
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 9999;
  }

  .content {
    position: fixed;
    top: 50%;
    background: #fff;
    box-shadow: 0 0 15px rgba(0,0,0, 0.7);
    left: 50%;
    z-index: 9999;
    border-radius: 0 0 ${props => props.theme.borderRadius} ${props => props.theme.borderRadius};
    -webkit-animation: slide-bottom 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
    animation: slide-bottom 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
    overflow-y: auto;
  }

  .close {
    position: fixed;
    top: 50px;
    right: 50px;
    z-index: 9999;
    cursor: pointer;
    color: #fff;
    border-radius: 100%;
    display: flex;
    height: 50px;
    width: 50px;
    font-weight: bold;
    align-items: center;
    justify-content: center;
    font-size: 48px;
  }

  @media(max-width: ${props => props.theme.breakpoints.mobile}) {
    .close {
      top: 10px;
      right: 10px;
    }
  }
`
