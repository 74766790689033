import React from 'react';
import { StyledModal } from './style';

const Modal = (props) => {
  const attrs = { ...props };
  delete attrs.onCloseClick;

  return(
    <StyledModal {...attrs}>
      <div className="overlay" onClick={props.onCloseClick}></div>
      <div className="content">
        {props.children}
      </div>

      <div className="close" onClick={props.onCloseClick}>
        &times;
      </div>
    </StyledModal>
  )
}

export default Modal;
