import React, { useEffect, useState } from 'react';
import api from '../../services/api';
import { useSelector, useDispatch } from 'react-redux';
import { updateCoffees } from '../../state/reducers/coffees';
import CoffeePackage from './coffeePackage';
import ReactInterval from 'react-interval';

import { Row, Col } from '../grid';
import Title from '../title';
import Subtitle from '../subtitle';
import Description from '../description';
import { LinkButton } from '../button';
import Container from '../container';
import Icon from '../icon';

import { StyledCoffees } from './style';

function Coffees(){
  const dispatch = useDispatch();

  const coffees = useSelector((state) => state.coffees);

  const [currentCoffee, setCurrentCoffee] = useState(null);
  const [gradientStyle, setGradientStyle] = useState({});
  const [destroyInterval, setDestroyInterval] = useState(false);

  const requestCoffees = () => {
    api.get('/coffees').then((response) => {
      if(response.data.coffee_beans){
        dispatch(updateCoffees(response.data.coffee_beans));
      }
    }).catch(() => {});
  }

  const handleInterval = () => {
    nextCoffee();
  }

  const prevCoffee = () => {
    let index = currentCoffee - 1;
    if(index === -1) index = coffees.length - 1;

    setCurrentCoffee(index);
    setDestroyInterval(true);
  }

  const nextCoffee = () => {
    let index = currentCoffee + 1;
    if(!coffees[index]) index = 0;

    setCurrentCoffee(index);
    setDestroyInterval(true);
  }

  useEffect(() => {
    if(destroyInterval === true) setDestroyInterval(false);
  }, [destroyInterval]);

  useEffect(() => {
    if(!coffees.length) return;

    setCurrentCoffee(0);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [coffees]);

  useEffect(() => {
    if(currentCoffee === null) return;

    const coffee = coffees[currentCoffee];

    if(coffee){
      setGradientStyle({
        '--gradient-from': coffee.gradient_from_color,
        '--gradient-mid': coffee.gradient_mid_color,
        '--gradient-to': coffee.gradient_to_color
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentCoffee]);

  useEffect(() => {
    requestCoffees();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return(
    <StyledCoffees style={gradientStyle}>
      {destroyInterval ? null : <ReactInterval timeout={3000} enabled={true} callback={handleInterval} />}
      <div className="box">
        <Container>
          <Row>
            <Col>
              <CoffeePackage
                currentCoffee={currentCoffee}
                prevCoffee={prevCoffee}
                nextCoffee={nextCoffee}
              />
            </Col>
            <Col>
              <Subtitle>Qualidade e Variedade</Subtitle>
              <Title>{coffees.length} cafés diferentes</Title>
              <Description>
                Estamos presentes em diversas regiões do Brasil para trazer os melhores cafés para a sua casa! 
              </Description>

              <Row className="items">
                <Col>
                  <Icon icon="award" className="icon" />

                  <h3>Café acima de 84 pontos</h3>
                </Col>

                <Col>
                  <Icon icon="magic" className="icon" />

                  <h3>Torra fresca</h3>
                </Col>
              </Row>

              <div className="actions">
                <LinkButton
                  href="/assinar?utm_source=site&utm_medium=coffees-cta"
                  buttonStyle="inverse"
                >
                  Criar assinatura
                </LinkButton>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </StyledCoffees>
  )
}

export default Coffees;
