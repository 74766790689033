import React from 'react';
import styled from 'styled-components';

import downloadAppStore from '../images/download-app-store.svg';
import downloadGooglePlay from '../images/download-google-play.svg';
import logo from '../images/logo.svg';

import instagramIcon from '../images/instagram-icon.svg';
import tiktokIcon from '../images/tiktok-icon.svg';
import youtubeIcon from '../images/youtube-icon.svg';
import facebookIcon from '../images/facebook-icon.svg';

import Container from './container';

const StyledFooter = styled.div`
  background: rgb(200,200,200);
  background: linear-gradient(35deg, #ccc 0%, #f8f8f8 50%);
  padding: 100px 0 0 0;
  font-size: ${({ theme }) => theme.fontSizes.extraSmall};

  .footer-nav {
    display: flex;

    .footer-menu {
      width: 15%;

      h4 {
        font-weight: 600;
        font-size: ${({ theme }) => theme.fontSizes.small};

        img {
          filter: grayscale(100%) brightness(0.6);
          height: 32px;
          margin-top: -20px;
          position: relative;
          top: 10px;
        }
      }

      p {
        margin: 30px 0 15px;
      }

      ul {
        margin-top: 30px;

        li {
          line-height: 1.6;

          a {
            color: ${({ theme }) => theme.colors.text};
          }

          & + li {
            margin-top: 13px;
          }
        }
      }

      .social-networks {
        margin-top: 30px;
        padding-top: 30px;
        border-top: 1px ${({ theme }) => theme.colors.border} solid;

        h4 {
          margin-bottom: 20px;
        }

        a {
          img {
            height: 32px !important;
          }
        }
      }

      &.download-app {
        width: 45%;
        margin-left: 10%;

        a {
          img {
            height: 42px;
          }

          & + a {
            margin: 0 0 0 10px;
          }
        }
      }
    }
  }

  .footer-bottom {
    display: flex;
    padding: 30px 0;
    margin: 50px 0 0;
    border-top: 1px ${({ theme }) => theme.colors.border} solid;

    .copyright {
      margin-right: 50px;
    }

    ul {
      display: flex;

      li {
        a {
          color: ${({ theme }) => theme.colors.text};
        }

        & + li {
          &:before {
            content: '';
            display: inline-block;
            height: 3px;
            width: 3px;
            border-radius: 100%;
            background: ${({ theme }) => theme.colors.text};
            margin: 0 15px;
            opacity: 0.5;
            position: relative;
            top: -3px;
          }
        }
      }
    }
  }

  @media(max-width: ${props => props.theme.breakpoints.mobile}) {
    padding: 30px 15px;

    .footer-nav {
      display: block;

      .footer-menu {
        width: 100%;

        p {
          margin-top: 15px;
        }

        ul {
          margin-top: 15px;
        }

        & + .footer-menu {
          margin: 30px 0 0;
        }

        &.download-app {
          width: 100%;
        }
      }
    }
    .footer-bottom {
      display: block;
      padding-bottom: 0;

      ul {
        margin: 15px 0 0;
      }
    }
  }
`

const Footer = ({ menuLinks }) => {
  return (
    <StyledFooter>
      <Container>
        <div className="footer-nav">
          <div className="footer-menu">
            <h4>
              <img src={logo} alt="coffee&joy" />
            </h4>

            <ul>
              <li><a href="/sobre">Sobre</a></li>
              <li><a href="/store">Loja</a></li>
              <li><a href="/revenda">Revenda</a></li>
              <li><a href="/coffees">Nossos cafés</a></li>
              <li><a href="https://blog.coffeeandjoy.com.br/" target="_blank" rel="noreferrer">Blog</a></li>
            </ul>
          </div>

          <div className="footer-menu">
            <h4>Aprenda</h4>

            <ul>
              <li><a href="/preparations">Métodos de preparo</a></li>
              <li><a href="/roast">Como nossos cafés são torrados?</a></li>
              <li><a href="http://sobrecafe.coffeeandjoy.com.br/" target="_blank" rel="noreferrer">Sobre café</a></li>
            </ul>
          </div>

          <div className="footer-menu">
            <h4>Contato</h4>

            <ul>
              <li><a href="tel:+553130809820">+55 (31) 3080-9820</a></li>
              <li><a href="mailto:contato@coffeeandjoy.com.br">contato@coffeeandjoy.com.br</a></li>
              <li><a href="/contact">Enviar uma mensagem</a></li>
            </ul>
          </div>

          <div className="footer-menu download-app">
            <h4>Baixe nosso aplicativo</h4>

            <p>Controle sua assinatura de qualquer lugar a qualquer momento</p>

            <a href="https://play.google.com/store/apps/details?id=com.candjapp" target="_blank" rel="noreferrer">
              <img src={downloadGooglePlay} alt="Baixar na Google Play" />
            </a>

            <a href="https://apps.apple.com/ca/app/coffee-joy/id1486471617" target="_blank" rel="noreferrer">
              <img src={downloadAppStore} alt="Baixar na App Store" />
            </a>

            <div className="social-networks">
              <h4>Siga-nos nas redes sociais</h4>

              <a href="https://www.instagram.com/coffee_and_joy/" target="_blank" rel="noreferrer">
                <img src={instagramIcon} alt="Instagram" />
              </a>

              <a href="https://www.tiktok.com/@coffee_and_joy" target="_blank" rel="noreferrer">
                <img src={tiktokIcon} alt="TikTok" />
              </a>

              <a href="https://www.youtube.com/c/CoffeeAndJoy" target="_blank" rel="noreferrer">
                <img src={youtubeIcon} alt="Youtube" />
              </a>

              <a href="https://www.facebook.com/coffeeandjoy" target="_blank" rel="noreferrer">
                <img src={facebookIcon} alt="Facebook" />
              </a>
            </div>
          </div>
        </div>

        <div className="footer-bottom">
          <div className="copyright">
            &copy; coffee&joy {new Date().getFullYear()}
          </div>

          <ul>
            <li><a href="/return-policy">Política de devolução e privacidade</a></li>
            <li><a href="/terms">Termos de uso</a></li>
          </ul>
        </div>
      </Container>
    </StyledFooter>
  )
}

export default Footer;
