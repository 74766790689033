import React from 'react';

import thumbsUp from '../../images/thumbs-up.svg';

import { StyledApproval } from './style';

const Approval = () => {
  return (
    <StyledApproval>
      <img src={thumbsUp} alt="" className="thumbs-up" />

      <big>92%</big> de aprovação
    </StyledApproval>
  )
}

export default Approval;
