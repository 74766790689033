export const theme = {
  header: {
    height: '60px'
  },
  fontFamily: 'Montserrat',
  colors: {
    body: '#fff',
    text: '#333',
    primary: '#d93f28',
    darkenPrimary: '#a52f1d',
    highlight: '#ed7600',
    border: '#ddd',

    hover: {
      primary: '#bd2008',
      highlight: '#ce6600',
    }
  },
  fontSizes: {
    extraSmall: '12px',
    small: '14px',
    normal: '16px',
    large: '18px',
    extraLarge: '22px',
    big: '36px',
    bigger: '44px',
  },
  borderRadius: '3px',
  containerWidth: '1170px',

  breakpoints: {
    mobile: '568px',
    tablet: '768px',
    desktop: '1024',
  }
}

export default theme;
