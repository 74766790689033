import React, { useState } from 'react';

import { LinkButton } from '../button';
import { StyledMenu } from './style';
import Icon from '../icon';

const Menu = ({ menuLinks, children }) => {
  const [showMenu, setShowMenu] = useState(false);
  const menuClass = showMenu ? '' : 'hide-menu';

  const close = () => {
    setShowMenu(false);
  }

  const open = () => {
    setShowMenu(true);
  }

  return (
    <StyledMenu className={menuClass}>
      <div className="menu-box">
        <ul class="parent-menu">
          {menuLinks.map(link => (
            <li
              key={link.name}
              data-has-submenu={link.subMenu && link.subMenu.length > 0 ? "true" : "false"}
            >
              <a href={link.link} className="menu-link">
                {link.name}

                {link.subMenu && link.subMenu.length > 0 ? <Icon icon="caret-down" className="caret" /> : null}
              </a>

              {link.subMenu && link.subMenu.length > 0 ? (
                <ul>
                  {link.subMenu.map((subLink) => (
                    <li key={subLink.name}>
                      <a
                        href={subLink.link}
                        className="menu-link"
                        target={subLink.target ? subLink.target : ""}
                      >
                        {subLink.name}
                      </a>
                    </li>
                  ))}
                </ul>
              ) : null}
            </li>
          ))}

          <li className="sep"></li>

          <li>
            <a href="/login" className="menu-link">Login</a>
          </li>
          <li>
            <LinkButton
              className="menu-cta button button-small"
              href="/assinar?utm_source=site&utm_medium=header-cta"
              size="small"
            >
              Criar assinatura
            </LinkButton>
          </li>
        </ul>

        <button onClick={close} className="close">
          &times;
        </button>
      </div>

      <button onClick={open} className="menu-icon">
        <Icon icon="bars" />
      </button>
    </StyledMenu>
  )
}

export default Menu;
