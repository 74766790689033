import styled from 'styled-components';
import ReactSlider from 'react-slider';

export const StyledShipping = styled.div`
  padding: 0 0 100px;

  .box {
    background: #fff;
    padding: 50px;
    max-width: 960px;
    margin: 0 auto;
    border: 1px ${({ theme }) => theme.colors.border} solid;
    border-radius: ${({ theme }) => theme.borderRadius};

    .title {
      font-size: ${({ theme }) => theme.fontSizes.big};
    }
  }

  .actions {
    margin: 30px 0 0;
    text-align: center;
  }

  .col {
    display: flex;
    flex-wrap: wrap;
    align-content: center;
  }

  @media(max-width: ${props => props.theme.breakpoints.mobile}) {
    padding: 0 15px 30px;

    .title, .subtitle {
      text-align: center;
      width: 100%;
    }

    .box {
      padding: 30px;

      .title {
        font-size: ${({ theme }) => theme.fontSizes.extraLarge};
      }
    }

    .col {
      text-align: center;

      &.shipping-col {
        text-align: left;
        padding: 0;
      }
    }
  }
`

export const StyledCalculator = styled.div`
  position: relative;
  width: 100%;
  margin-left: 80px;

  .warning {
    position: absolute;
    top: -60px;
    left: 30px;
    animation: bounce 0.5s infinite alternate;
    -webkit-animation: bounce 0.5s infinite alternate;
    font-family: Caveat;

    @keyframes bounce {
      from {
        transform: translateY(0px) rotate(-7deg);
      }
      to {
        transform: translateY(-15px) rotate(-7deg);
      }
    }
    @-webkit-keyframes bounce {
      from {
        transform: translateY(0px) rotate(-7deg);
      }
      to {
        transform: translateY(-15px) rotate(-7deg);
      }
    }

    .arrow {
      height: 32px;
      opacity: 0.3;
    }

    .text {
      position: relative;
      top: -25px;
      margin: 0 0 0 5px;
      color: rgba(0,0,0, 0.4);
      font-size: 26px;
    }
  }

  h3 {
    margin: 0 0 15px;

    small {
      font-size: ${({ theme }) => theme.fontSizes.extraSmall};
      /* opacity: 0.6; */
      position: relative;
      top: -2px;
      margin: 0 0 0 10px;
    }
  }

  .slider-wrapper {
    width: 100%;
    margin: 40px 0 0;
    padding-right: 110px;
    position: relative;
  }

  .bubble {
    position: absolute;
    top: -20px;
    right: 0;
  }

  @media(max-width: ${props => props.theme.breakpoints.mobile}) {
    margin: 80px 0 30px;

    .warning {
      left: 0;
      top: -40px;

      .arrow {
        height: 24px;
      }
      .text {
        font-size: 20px;
        top: -15px;
      }
    }
  }
`

export const StyledBubble = styled.div`
  height: 50px;
  border-radius: 25px;
  width: 85px;
  background: #fff;
  border: 1px ${({ theme }) => theme.colors.border} solid;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bold;
  text-align: center;
  font-size: ${({ theme }) => theme.fontSizes.small};
  position: relative;

  &:before {
    border-color: transparent ${({ theme }) => theme.colors.border} transparent transparent !important;
    left: -8px !important;
    border-width: 9px 9px 9px 0 !important;
    margin-top: -9px !important;
  }

  &:before, &:after {
    content: '';
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 7px 7px 7px 0;
    border-color: transparent #fff transparent transparent;
    position: absolute;
    top: 50%;
    left: -6px;
    margin-top: -7px;
  }

  &.free-shipping {
    background: #F5B744;
    border-color: #F5B744;
    color: #fff;

    &:before, &:after {
      border-color: transparent #F5B744 transparent transparent !important;
    }
  }
`

export const StyledZipcodeForm = styled.div`
  background: #f8f8f8;
  border-radius: ${props => props.theme.borderRadius};
  padding: 30px;
  width: 100%;
  margin-left: 50px;

  .form-title {
    font-size: ${({ theme }) => theme.fontSizes.normal};
    line-height: 1.4;
    font-weight: 600;
    margin: 0 0 15px;
  }

  .input {
    border: 1px ${({ theme }) => theme.colors.border} solid;
    border-radius: ${props => props.theme.borderRadius};
    font-size: ${({ theme }) => theme.fontSizes.normal};
    line-height: 34px;
    padding: 2px 15px 0;
  }

  .error-message {
    color: ${({ theme }) => theme.colors.primary};
    font-size: ${({ theme }) => theme.fontSizes.small};
    line-height: 30px;
  }

  button {
    margin-top: 10px;
  }

  @media(max-width: ${props => props.theme.breakpoints.mobile}) {
    margin-left: 0;
    margin-top: 30px;
    padding: 15px;
  }
`

export const StyledThumb = styled.div`
  height: 30px;
  line-height: 30px;
  width: 30px;
  text-align: center;
  background: ${({ theme }) => theme.colors.primary};
  border: 5px #fff solid;
  box-shadow: 0 1px 1px 1px rgba(0,0,0, 0.2);
  box-sizing: unset;
  color: #fff;
  font-weight: bold;
  border-radius: 50%;
  cursor: grab;
  margin: -17px 0 0;

  &:focus {
    background: ${({ theme }) => theme.colors.hover.primary};
    outline: none;
  }

  &:after {
    content: '${props => props['aria-valuenow'] === 1 ? 'Pacote' : 'Pacotes'}';
    position: absolute;
    bottom: -23px;
    left: 50%;
    margin-left: -50px;
    font-weight: normal;
    width: 100px;
    line-height: 1;
    color: ${({ theme }) => theme.colors.text};
    font-size: ${({ theme }) => theme.fontSizes.extraSmall};
  }
`;

export const StyledTrack = styled.div`
  top: 0;
  bottom: 0;
  background: #eee;
  border: 1px ${({ theme }) => theme.colors.border} solid;
  border-radius: 999px;
`;

export const StyledSlider = styled(ReactSlider)`
  width: 100%;
  height: 10px;
`;
