import React, { useState, useEffect } from 'react';
import styled from 'styled-components';

import Container from './container';
import Logo from './logo';
import Menu from './menu';

const StyledHead = styled.div`
  background: none;
  left: 0;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 9999;

  .container {
    align-items: center;
    display: flex;
    height: ${({ theme }) => theme.header.height};
    justify-content: space-between;
  }

  @media(max-width: 960px) {
    .container {
      justify-content: center;
    }
  }

  &.floating {
    background: #fff;
    box-shadow: 0 0 10px rgba(0,0,0, 0.13);
  }
`

const Header = ({ menuLinks }) => {
  const [offset, setOffset] = useState(0);

  useEffect(() => {
    const onScroll = () => setOffset(window.scrollY);
    
    window.removeEventListener('scroll', onScroll);
    window.addEventListener('scroll', onScroll, { passive: true });
    return () => window.removeEventListener('scroll', onScroll);
  }, []);

  return (
    <StyledHead id="header" className={offset > 0 ? 'floating' : ''}>
      <Container>
        <Logo />
        <Menu menuLinks={menuLinks} />
      </Container>
    </StyledHead>
  )
}

export default Header;
