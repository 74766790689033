import React from 'react';

import {
  StyledButton,
  StyledLinkButton,
  StyledTextButton,
  IconWrapperRight,
  IconWrapper
} from './style';
import { Icon } from '../icon';

export const ButtonComponent = (props) => {
  let attrs = { ...props };

  let classes = ['button'];
  if(attrs.className) classes.push(attrs.className);
  if(attrs.size) classes.push(`button-${attrs.size}`);
  if(attrs.buttonStyle) classes.push(`button-${attrs.buttonStyle}`);
  if(attrs.align) classes.push(`button-${attrs.align}`);

  attrs.type = attrs.type || 'button';

  let icon;
  if(attrs.icon) {
    let Wrapper = attrs.align === 'right' ? IconWrapperRight : IconWrapper;

    icon = <Wrapper className="wrapper">
      <Icon icon={attrs.icon} />
    </Wrapper>
  }

  const Component = attrs.buttonType === 'link' ? StyledLinkButton : StyledButton;

  return (
    <Component className={classes.join(' ')} {...attrs}>
      {attrs.children}
      {icon}
    </Component>
  )
}

export const Button = (props) => {
  return (
    <ButtonComponent {...props} buttonType="button" />
  )
}

export const LinkButton = (props) => {
  return (
    <ButtonComponent {...props} buttonType="link" />
  )
}


export const TextButton = (props) => {
  let attrs = { ...props };

  let classes = ['button'];
  if(attrs.className) classes.push(attrs.className);
  if(attrs.size) classes.push(`button-${attrs.size}`);
  if(attrs.buttonStyle) classes.push(`button-${attrs.buttonStyle}`);

  attrs.type = attrs.type || 'button';

  return (
    <StyledTextButton className={classes.join(' ')} {...attrs}>
      {attrs.children}
    </StyledTextButton>
  )
}
