import React from 'react';

import numeral from 'numeral';
import 'numeral/locales/pt-br';

numeral.locale('pt-br');

const Numeral = (props) => {
  const className = props.className
	const value = props.value
	const format = props.format
	const formated = (format)?numeral(value).format(format):numeral(value)
	if(value >= 0)
	  return <span className={`${(className)?className:''}`}>{formated}</span>
	else return (null)
}

export default Numeral;
