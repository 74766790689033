import React from 'react';

import Numeral from '../numeral';
import { StyledProduct } from './style';

const Product = ({ product }) => {
  return(
    <StyledProduct className="product">
      <a className="product-box" href={product.url} target="_blank" rel="noopener noreferrer">
        <span className="product-image">
          <img src={product.default_image_url} alt={product.name} />

          {product.discount_percentage ? <span className="product-discount">{product.discount_percentage}</span> : null}
        </span>

        <span className="product-name">
          {product.name}
        </span>

        {product.original_price ? <span className="product-original-price">De <span className="original-price"><Numeral value={product.original_price} format="$ 0,0.00" /></span> Por </span> : null}

        <span className="product-price">
          <Numeral value={parseFloat(product.price)} format="$ 0,0.00" />
        </span>
      </a>
    </StyledProduct>
  )
}

export default Product;
