import styled from 'styled-components';

export const StyledCoffees = styled.div`
  background-color: var(--gradient-to);

  .box {
    background: rgb(200,200,200);
    background: linear-gradient(135deg, rgb(215, 215, 215, 0.9) 10%, rgba(255,255,255,1) 50%);
    padding: 100px 0;
    position: relative;

    &:before, &:after {
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      height: 45%;
      width: 100%;
      background: linear-gradient(135deg, rgb(215, 215, 215, 0.9) 10%, rgba(255,255,255,1) 45%);
      z-index: 9;
    }

    &:before {
      background: var(--gradient-to);
    }
  }

  .subtitle {
    color: var(--gradient-from);
  }

  .title {
    background: linear-gradient(135deg, var(--gradient-to) 0%, var(--gradient-mid) 50%, var(--gradient-from) 70%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }

  .description {
    color: #000;
  }

  .items {
    margin: 30px 0 0;

    .icon {
      font-size: 36px;
      margin: 0 0 20px;
      color: var(--gradient-from);
    }

    h3 {
      font-weight: bold;
    }
  }

  .actions {
    margin: 50px 0 0;

    .button {
      border-color: var(--gradient-from);
      color: var(--gradient-from);
    }
  }

  .row {
    position: relative;
    z-index: 99;
  }

  @media(max-width: ${props => props.theme.breakpoints.mobile}) {
    .box {
      padding: 30px 15px;

      &:before, &:after {
        display: none;
      }

      .col {
        text-align: center;
      }
    }

    .subtitle {
      margin: 30px 0 0;
    }

    .items {
      .col {
        & + .col {
          margin-top: 30px;
        }
      }
    }
  }
`

export const StyledCoffeePackage = styled.div`
  text-align: center;
  position: relative;

  .coffee-name {
    font-size: 32px;
    margin-top: 10px;
    color: var(--gradient-to);
    text-transform: lowercase;
    font-size: ${({ theme }) => theme.fontSizes.extraLarge};
  }

  .package-box {
    display: inline-block;
    position: relative;
  }

  .package {
    position: relative;
    z-index: 2;
  }

  .shadow {
    position: absolute;
    bottom: 7px;
    right: -55px;
    z-index: 1;
    opacity: 0.3;
  }

  .package, .shadow {
    height: 400px;
  }

  .control-button {
    position: absolute;
    top: 50%;
    cursor: pointer;
    opacity: 0.3;
    z-index: 3;

    img {
      height: 20px;
    }

    &:hover {
      opacity: 1;
    }

    &.control-left {
      left: -30px;
    }

    &.control-right {
      right: -30px;
    }
  }

  @media(max-width: ${props => props.theme.breakpoints.mobile}) {
    .coffee-name {
      font-size: 24px;
    }

    .package, .shadow {
      height: 250px;
    }

    .shadow {
      right: -30px;
    }
  }
`
