import styled from 'styled-components';

export const StyledReferral = styled.div`
  background: #2d2720;
  color: #fff;
  padding: 20px 0;
  text-align: center;
  font-size: ${({ theme }) => theme.fontSizes.small};

  .highlight {
    font-weight: bold;
  }
`
