import React, { useEffect, useState } from 'react';
import Container from '../container';
import Cookies from 'universal-cookie';
import api from '../../services/api';

import { StyledReferral } from './style';

const Referral = () => {
  const [name, setName] = useState('');

  const checkReferral = () => {
    const cookies = new Cookies();
    const referralCode = cookies.get('_cj_referral_code');

    if(referralCode){
      api.get('/name-by-referral-code?code=' + referralCode).then((response) => {
        if(response.data && response.data.name) {
          setName(response.data.name);
        }
      }).catch(() => {});
    }
  }

  useEffect(() => {
    checkReferral();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if(name) {
    return(
      <StyledReferral>
        <Container>
          Você foi convidado pelo seu amigo <span className="highlight">{name}</span>.
          Assine agora e ganhe um café secreto.
        </Container>
      </StyledReferral>
    )
  }
  else return null;
}

export default Referral;
