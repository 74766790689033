import React from 'react';
import { useSelector } from 'react-redux';

import Modal from '../modal';
import { StyledReviewModal } from './style';

const ReviewVideo = ({ url }) => {
  return (
    <div class="review-video">
      <video loop autoplay muted webkit-playsinline playsinline>
        <source src={url} type="video/mp4" />
      </video>
    </div>
  )
}

const ReviewImage = ({ url }) => {
  return (
    <div class="review-image">
      <img src={url} alt="" />
    </div>
  )
}

const ReviewModal = ({ isOpen, onCloseClick }) => {
  const currentReview = useSelector((state) => state.currentReview);

  if(isOpen && currentReview){
    return (
      <Modal onCloseClick={onCloseClick}>
        <StyledReviewModal>
          {currentReview.type === 'image' ? <ReviewImage url={currentReview.url} /> : <ReviewVideo url={currentReview.url} />}
        </StyledReviewModal>
      </Modal>
    )
  }
  else return null;
}

export default ReviewModal;
