import styled from 'styled-components';

export const StyledBanners = styled.div`
  position: relative;
  margin-bottom: -5px;

  .banner {
    background-size: auto 100%;
    background-position: center;
    display: block;
    padding-top: 41.66%;
    position: relative;

    .banner-mobile {
      display: none;
    }

    .banner-box {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: flex-start;
      padding-left: 7%;
      box-sizing: border-box;

      .headline {
        width: 50%;
        height: 100%;
        display: flex;
        flex-wrap: wrap;
        align-content: center;
        padding-right: 7%;

        h1 {
          width: 100%;
          font-size: ${({ theme }) => theme.fontSizes.big};
          font-weight: bold;
        }

        p {
          margin: 15px 0 30px;
          font-size: ${({ theme }) => theme.fontSizes.large};
          line-height: 1.4;
          width: 100%;
        }

        .banner-actions {
          display: flex;
          width: 100%;
          justify-content: space-between;

          a {
            width: 48%;
            justify-content: center;
          }
        }
      }
    }
  }

  .slick-dotted {
    &.slick-slider{
      margin-bottom: 0;
    }
  }

  .slick-arrow {
    display: none !important;
  }

  .slick-dots {
    bottom: auto;
    top: 50%;
    right: 30px;
    width: auto;
    transform: translateY(-50%);
    background: rgba(0,0,0, 0.2);
    padding: 10px 3px;
    border-radius: 5px;

    li {
      display: block;

      button {
        border: 3px #FFF solid;
        border-radius: 100%;

        &:before {
          display: none;
        }
      }

      &.slick-active {
        button {
          background: #FFF;
        }
      }

      & + li {
        margin-top: 15px;
      }
    }
  }

  @media(max-width: ${props => props.theme.breakpoints.mobile}) {
    .banner {
      padding-top: ${props => props.theme.header.height};
      background-image: none !important;

      .banner-mobile {
        display: block;
        width: 100%;
      }

      .banner-box {
        padding: 30px 0 0;
        position: static;
        text-align: center;

        .headline-box {
          padding: 0 30px;
        }

        .headline {
          width: 100%;
          padding: 0;

          p {
            margin-bottom: 10px;
            font-size: ${({ theme }) => theme.fontSizes.normal};
          }

          .banner-actions {
            background: #f1f1f1;
            padding: 0 30px 30px;
            display: block;

            a {
              width: 100%;

              & + a {
                margin-top: 10px;
              }
            }
          }
        }
      }
    }

    .slick-dots {
      top: auto;
      bottom: 2px;
      right: auto;
      left: 50%;
      padding: 0;
      background: none;
      transform: translateX(-50%);

      li {
        display: inline-block;

        button {
          border-color: #999;
          height: 10px;
          width: 10px;
        }

        &.slick-active {
          button {
            background: #999;
          }
        }

        & + li {
          margin-top: 0;
        }
      }
    }
  }
`
