import { createGlobalStyle } from 'styled-components';
import reset from 'styled-reset';

import '@fontsource/montserrat/300.css';
import '@fontsource/montserrat/400.css';
import '@fontsource/montserrat/500.css';
import '@fontsource/montserrat/600.css';
import '@fontsource/montserrat/700.css';

export const GlobalStyle = createGlobalStyle`
  ${reset}

  html {
    box-sizing: border-box;
  }

  *,
  *::before,
  *::after {
    box-sizing: inherit;
  }

  body {
    /* padding-top: ${props => props.theme.header.height}; */
    font-family: ${({ theme }) => theme.fontFamily};
    color: ${({ theme }) => theme.colors.text};
    font-size: ${({ theme }) => theme.fontSizes.normal};
  }

  a {
    color: ${({ theme }) => theme.colors.primary};

    &:hover {
      color: ${({ theme }) => theme.colors.darkenPrimary};
    }
  }

  strong {
    font-weight: bold;
  }
`
