import React from 'react';
import styled from 'styled-components';

import logo from '../images/logo.svg';

const StyledLogo = styled.div`
  img {
    height: 40px;
  }

  @media(max-width: 960px) {
    img {
      height: 35px;
    }
  }
`

const Logo = () => {
  return (
    <StyledLogo className="logo">
      <img src={logo} alt="" />
    </StyledLogo>
  )
}

export default Logo;
