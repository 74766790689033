import React from 'react';

import { theme } from '../styles/theme';
import { ThemeProvider } from 'styled-components';
import { GlobalStyle } from '../styles/global-style';

import Layout from '../templates/layout';

import Banners from '../templates/banners';
import Referral from '../templates/referral';
// import Hero from '../templates/hero';
import HowItWorks from '../templates/howItWorks';
import Shipping from '../templates/shipping';
import Coffees from '../templates/coffees';
import Reviews from '../templates/reviews';
import Teodoro from '../templates/teodoro';
import Store from '../templates/store';
import MobileApp from '../templates/mobileApp';
import Faq from '../templates/faq';
import Newsletter from '../templates/newsletter';
import Points from '../templates/points';
import CookiesWarning from '../templates/cookiesWarning';

// markup
const IndexPage = () => {
  return (
    <ThemeProvider theme={theme}>
      <GlobalStyle />
      <Layout>
        <Referral />
        <Banners />
        {/* <Hero /> */}
        <HowItWorks />
        <Shipping />
        <Coffees />
        <Store />
        <Teodoro />
        <Reviews />
        <Points />
        <MobileApp />
        <Faq />
        <Newsletter />
        <CookiesWarning />
      </Layout>
    </ThemeProvider>
  )
}

export default IndexPage
