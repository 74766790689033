import React from 'react';
import styled from 'styled-components';

export const StyledRow = styled.div`
  display: flex;

  .col {
    width: 50%;
  }

  @media(max-width: ${props => props.theme.breakpoints.mobile}) {
    display: block;

    .col {
      width: 100%;
    }
  }
`

export const Row = ({ children, className }) => {
  let klass = 'row';
  if(className) klass += ` ${className}`;

  return <StyledRow className={klass}>{children}</StyledRow>;
}

export const Col = ({ children, className }) => {
  let classes = ['col', className];

  return <div className={classes.join(' ')}>{children}</div>;
}
